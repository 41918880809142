













import { Component, Mixins, Prop } from "vue-property-decorator";
import { JournalRecord } from "@/views/chat/journal/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import TableItem from "@/views/chat/journal/table/table-item.vue";

import { UseFields } from "piramis-base-components/src/components/Pi/index";

@Component({
  components: {
    TableItem,
  }
})
export default class JournalTable extends Mixins(UseFields, TariffsTagsHelper) {
  @Prop({ required: true }) logs!: Array<JournalRecord>
  @Prop({ required: true }) visible!: number

  titles: Array<string> = [ /*"uid",*/"user", "event_type", "reason_type", "punish_type", "time" ]
}
