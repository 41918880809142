




















import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class JournalRecordMessage extends Vue {
  @Prop() sectionTitle!:string

  @Prop() message!:string

  openMessage = false

  abbreviationMessage(message: string,): string {
    return `${ message.slice(0, 300) }...`
  }
}
