
















































































































type TableItemUser = {
  message: string,
  id: number,
  avatar: string,
  name: string,
  login: string | null
}

import { JournalRecord } from "@/views/chat/journal/types";
import isMobile from "@/assets/utils/isMobile";
import JournalRecordUserInfo from './JournalRecordUserInfo.vue'
import JournalRecordMessage from "./JournalRecordMessage.vue";

import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { snakeCase } from "lodash";
import moment from 'moment'

@Component({
  components: {
    JournalRecordUserInfo,
    JournalRecordMessage
  },
  data() {
    return {
      isMobile,
      snakeCase,
      moment
    }
  }
})
export default class TableItem extends Vue {
  @Prop({ required: true }) item!: JournalRecord

  actor: TableItemUser = {
    avatar: this.item.actor_user_avatar,
    id: this.item.actor_user_id,
    login: this.item.actor_user_login,
    message: this.replaceNewLine(this.item.actor_message),
    name: this.item.actor_user_name
  }

  target: TableItemUser = {
    avatar: this.item.target_user_avatar,
    id: this.item.target_user_id,
    login: this.item.target_user_login,
    message: this.replaceNewLine(this.item.target_message),
    name: this.item.target_user_name
  }

  get hasExtra():boolean {
    return !!this.target.message.length || !!this.actor.message.length || !!(this.item.description as any).length
  }

  get isFilterRecord() {
    return this.item.filter_type !== 'None'
  }

  get filterType() {
    if (this.item.filter_type === 'Warn') {
      return `( ${ this.$t('filter_warn_title', {
        actor_name: this.item.actor_user_name,
        target_name: this.item.target_user_name,
        actor_login: this.item.actor_user_login,
        target_login: this.item.target_user_name,
      }) } )`
    }

    return `(${ this.$t(`filter_${ snakeCase(this.item.filter_type) }_title`) })`
  }

  check(original: string, masked: string, originalIndex: number, maskedIndex: number, oms: string): boolean {
    while (originalIndex >= 0 || maskedIndex >= 0) {
      let os = original[originalIndex]
      let ms = masked[maskedIndex]
      if (ms === oms) {
        break
      }
      if (os !== ms) {
        return false
      }
      originalIndex--
      maskedIndex--
    }
    return true
  }

  getDescription(): string {
    const target = this.target.name ? this.target.name : this.target.login
    const actor = this.actor.name ? this.actor.name : this.actor.login
    const targetLogin = this.target.login ? this.target.login : this.target.id
    const actorLogin = this.actor.login ? this.actor.login : this.target.id
    if (this.item.filter_type === 'Warn' && !(this.item.description as string).length) {
      return this.$t('filter_warn_title', {
        actor_name: actor,
        target_name: target,
        actor_login: actorLogin,
        target_login: targetLogin
      }).toString()
    } else {
      return this.replaceNewLine(this.item.description as string)
    }
  }

  replaceNewLine(html: string): string {
    return html?.replace(/\\n/g, "<br/>") ?? '';
  }

}
