
























import { ANONYMOUS_USER_LOGIN } from '@/includes/constants'
import TelegramProfileButton from "@/components/TelegramProfileButton.vue";

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    UserMainInfo,
    TelegramProfileButton,
  },
  data() {
    return {
      ANONYMOUS_USER_LOGIN
    }
  }
})
export default class JournalRecordUserInfo extends Vue {
  @Prop() sectionTitle!:string

  @Prop() userAvatar!:string

  @Prop() userName!:string

  @Prop() userLogin!:string | null

  @Prop() userId!:number

  gotoProfile() {
    this.$router.push({
      name: 'user_info',
      params: {
        userId: this.userId.toString()
      }
    })
  }
}
